<script>
import { shallowRef } from "vue";
import { layoutComputed, authComputed } from "@/state/helpers";
import { BookIcon, KeyIcon, DownloadIcon, UserIcon, ClipboardIcon, BookOpenIcon, SettingsIcon, ListIcon } from "@zhuowenli/vue-feather-icons";

export default {
  components: {
    BookIcon,
    KeyIcon,
    DownloadIcon,
    UserIcon,
    ClipboardIcon,
    BookOpenIcon,
    SettingsIcon,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },

      menu: [
        { id: "debtors", title: "Должники", icon: shallowRef(UserIcon), path: "/debtors", childs: [] },
        { id: "writs", title: "Журнал исков", icon: shallowRef(BookOpenIcon), path: "/writs", childs: [] },
        { id: "debtors-registries", title: "Рабочие реестры", icon: shallowRef(ListIcon), path: "/registries", childs: [] },
        { id: "reports", right: "hasRightReports", title: "Отчеты", icon: shallowRef(ClipboardIcon), path: "/reports", childs: [] },
        {
          id: "sidebarDashboards",
          right: "hasRightReferences",
          title: "Справочники",
          icon: shallowRef(BookIcon),
          childs: [
            { text: "Дома", path: "/data/houses" },
            { text: "ССП", path: "/data/bailiffs" },
            { text: "Судебные участки", path: "/data/courts" },
            { text: "Печатные формы", path: "/data/templates" },
          ],
        },
        { id: "import", title: "Обмен данными", right: "hasRightData", icon: shallowRef(DownloadIcon), path: "/import", childs: [] },
        {
          id: "settings",
          right: "hasRightSettings",
          title: "Настройки",
          icon: shallowRef(SettingsIcon),
          childs: [
            { text: "Роли", path: "/admin/roles" },
            { text: "Пользователи", path: "/admin/users" },
          ],
        },
      ],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,

    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },
  methods: {
    isExists(array) {
      let currentPath = this.$router.currentRoute.value.path;
      return array.filter((r) => r.path == currentPath).length > 0;
    },

    isActive(path) {
      let currentPath = this.$router.currentRoute.value.path;
      if (path == currentPath) {
        return true;
      }
      return false;
    },

    checkRight(rightName) {
      return this[rightName];
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100" id="navbar-nav">
      <li class="nav-item" v-for="(m, i) in menu" :key="i">
        <div v-if="!m.right || checkRight(m.right)">
          <router-link class="nav-link menu-link" :to="m.path" :class="{ active: isActive(m.path) }" v-if="m.path">
            <component :is="m.icon" />
            <span>{{ m.title }}</span>
          </router-link>
          <div v-else>
            <a class="nav-link menu-link" data-bs-toggle="collapse" role="button" aria-expanded="false" :href="'#' + m.id"
              :aria-controls="m.id" :class="{ active: isExists(m.childs) }">
              <component :is="m.icon" />
              <span data-key="t-dashboards">{{ m.title }}</span>
            </a>
            <div class="collapse menu-dropdown" :class="{ show: isExists(m.childs) }" :id="m.id">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item" v-for="(i, index) in m.childs" :key="index">
                  <router-link :to="i.path" class="nav-link custom-abc" :class="{ active: isActive(i.path) }">{{ i.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<style></style>
