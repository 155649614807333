<script>
import CurrentUser from "@/components/auth/current-user";
import { layoutMethods, layoutComputed } from "@/state/helpers";
import { HelpCircleIcon } from "@zhuowenli/vue-feather-icons";

/**
 * Nav-bar Component
 */
export default {
  computed: {
    ...layoutComputed,
  },
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      myVar: 1,
    };
  },
  components: { CurrentUser, HelpCircleIcon },
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document.getElementById("topnav-hamburger-icon").addEventListener("click", this.toggleHamburgerMenu);

    if (this.sidebarSize == "sm") document.querySelector(".hamburger-icon").classList.add("open");
    else document.querySelector(".hamburger-icon").classList.remove("open");
  },
  methods: {
    ...layoutMethods,
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767) document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse vertical menu
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") == "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") == "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }

      let isOpened = document.querySelector(".hamburger-icon").classList.contains("open");
      this.changeSidebarSize({ sidebarSize: isOpened ? "sm" : "lg" });
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <h4>DP</h4>
              </span>
              <span class="logo-lg">
                <h4>Debto</h4>
              </span>
            </router-link>
          </div>

          <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>

        <div class="d-flex align-items-center">
          <div>
            <a href="/userManual.pdf" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Руководство пользователя" target="_blank">
              <HelpCircleIcon class="help-icon text-muted fs-16 align-middle" />
            </a>
          </div>
          <CurrentUser />
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.help-icon {
  transition: all 0.3s;
  transform-origin: center center;
}
.help-icon:hover {
  transform: scale(1.3);
  /* width: 32px; */
  /* height: 32px; */
}
</style>
