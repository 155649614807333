<script setup>
import { ref } from "vue";
import axios from "axios";
import { onMounted } from "vue";
import { version } from "../../package";
import { formatDate } from "devextreme/localization";

const versionDate = formatDate(new Date(document.documentElement.dataset.buildTimestampUtc), 'shortDate');
const serverVersion = ref("");

onMounted(() => getServerVersion());
async function getServerVersion() {
  var response = await axios.get(`${process.env.VUE_APP_URL}/content/version`);
  serverVersion.value = response.data;
}
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() }} © Debto.</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">интерфейс {{ version }} ({{ versionDate }}) | сервер {{ serverVersion }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>
