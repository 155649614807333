import { mapState, mapGetters, mapActions } from "vuex";

export const authComputed = {
  ...mapState("auth", {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters("auth", ["loggedIn",
    "hasRightReports",
    "hasRightReferences",
    "hasRightData",
    "hasRightSettings",
    "hasRightExport",
    "hasRightAddEditWrit",
    "hasRightAddEditAgreement",
    "hasRightAddEditInhabitant",
    "hasRightAddEditDebtorNotes",
    "hasRightAddEditDebtorWorkLogs",
  ]),
};

export const layoutComputed = {
  ...mapState("layout", {
    layoutType: (state) => state.layoutType,
    sidebarSize: (state) => state.sidebarSize,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    mode: (state) => state.mode,
    position: (state) => state.position,
    sidebarView: (state) => state.sidebarView,
    sidebarColor: (state) => state.sidebarColor,
  }),
};

export const debtorsFiltersComputed = {
  ...mapState("debtorsFilters", {
    currentFilters: (state) => state.filters,
  }),
};
export const debtorsFiltersMethods = mapActions("debtorsFilters", ["saveFilters"]);

export const debtorCardInfoComputed = {
  ...mapState("debtorCardInfo", {}),
  ...mapGetters("debtorCardInfo", ["title", "address"]),
};
export const debtorCardInfoMethods = mapActions("debtorCardInfo", ["load"]);

export const authMethods = mapActions("auth", ["logIn", "logOut", "validate", "register", "resetPassword"]);

export const layoutMethods = mapActions("layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeSidebarSize",
  "changeTopbar",
  "changeMode",
  "changePosition",
  "changeSidebarView",
  "changeSidebarColor",
]);

export const notificationMethods = mapActions("notification", ["success", "error", "clear"]);
