<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <div class="page-title-right flex-fill">
          <ol class="breadcrumb m-0 pb-3 w-100">
            <li class="breadcrumb-item" v-for="(item, index) in items" :key="index" :class="{ active: item[0] }">
              <router-link v-if="item.href" :to="item.href">{{ item.text }}</router-link>
              <a v-else :href="item.href">{{ item.text }}</a>
            </li>
          </ol>
          <div class="d-flex flex-sm-row flex-column align-items-end">
            <h4 class="flex-fill mb-sm-0" v-html="title"></h4>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
