<template>
  <div class="dropdown ms-sm-3 header-item topbar-user">
    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span class="d-flex align-items-center">
        <img class="rounded-circle header-profile-user" src="@/assets/images/users/user-dummy-img.jpg" alt="Header Avatar" />
        <span class="text-start ms-xl-2">
          <div class="current-user">
            <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ loggedUser.userName }}</span>
          </div>
        </span>
      </span>
    </button>
    <div class="dropdown-menu dropdown-menu-end">
      <a class="dropdown-item" href="/logout">
        <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
        <span class="align-middle" data-key="t-logout">Выйти</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      loggedUser: "auth/loggedUser",
    }),
  },
};
</script>

<style>
.current-user {
}
</style>
